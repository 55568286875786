import { useSelector } from "react-redux"
import { daysUntil, formatDate, formatDateTime } from "../functions"

export const Account = () => {

    const statData = useSelector((state) => state.stat)

    return (
        <>
            <h1>Account</h1>
            <div className="account_expires">
                <div className="account_expires_content">
                    Expires at <div className="account_expires_content_when">{formatDate(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    Days left <div className="account_expires_content_when">{daysUntil(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    Pay for Cloak <div className="account_expires_content_when"><a href="https://t.me/sales_knk" target="_blank" rel="noopener noreferrer">@sales_knk</a></div>
                </div>
            </div>
            {statData.payments.length > 0
            &&
            <>
                <h2>Payments</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Summ, $</th>
                            <th>Period, days</th>
                            <th>When</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statData.payments.map((e,i) =>
                            <tr key={`paymentstr${i}`}>
                                <td>{e.summ}</td>
                                <td>{e.term}</td>
                                <td>{e.whenwas}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </>
            }
            <h2>Info</h2>
            <p>Email: {statData.email}</p>
            <p>Registered: {formatDateTime(statData.registered)}</p>
        </>
    )
}