import { useEffect, useState, useCallback } from "react"
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateLoader } from '../redux/statSlice'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faToggleOn, faToggleOff, faCopy, faCircleInfo, faTrash, faAdd } from "@fortawesome/free-solid-svg-icons"

import { smartSignalsRelations } from "../functions"

import countriesJson from "../countries.json"

export const Cloack = () => {


    const { id } = useParams();
    const dispatch = useDispatch();

    const [url1, setURL1] = useState('')
    const [url2, setURL2] = useState('')
    const [udPath,setUdPath] = useState('')
    const [countries, setCountries] = useState('');
    const [countriesStop, setCountriesStop] = useState('')
    const [delay, setDelay] = useState(0)
    const [cloackName, setCloackName] = useState('Loading...')
    const [getVars,setGetVars] = useState(0)
    const [getVarsValues,setGetVarsValues] = useState([])
    const [getVarsAndOr,setGetVarsAndOr] = useState('and')
    const [split,setSplit] = useState(0)
    const [splitValues,setSplitValues] = useState([])
    const [splitCountry,setSplitCountry] = useState('AF')
    const [splitWhite,setSplitWhite] = useState('')
    const [splitBlack,setSplitBlack] = useState('')
    const [checkboxes, setCheckboxes] = useState({
        active: false,
        gclid: false,
        vpn: false,
        incognito: false,
        bot: false,
        tampering: false,
        virtual_machine: false,
        privacy_settings: false,
        root_apps: false,
        emulator: false,
        app_cloners: false,
        jailbreak: false,
        frida: false,
        geo: false,
        windows: false,
        mac: false,
        ios: false,
        android: false,
        linux: false,
        chromeos: false,
        redirect: false,
        developer_tools: true,
        remote_control: true,
        factory_reset: true
    });

    // Универсальная функция для отправки изменений
    const sendDataToServer = async (name, value) => {
        dispatch(updateLoader(true))
        try {
            const stateSend = {
                action: 'savecheckbox',
                name: name,
                value: value,
                id: id
            };

            const response = await fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            });
            const json = await response.json()
            console.log(json)
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false))
        }
    };

    // Обработчики изменений инпутов
    const handleInputChange = (setter, name) => async (event) => {
        const value = name === 'name' ? event.target.value : event.target.value.replaceAll(' ', '').trim()
        setter(value);

        // Отправляем данные на сервер
        await sendDataToServer(name, value);
    };

    // Обработчик изменения чекбоксов
    const checkboxesChange = async (e) => {
        const { name, checked } = e.target;
        const newValue = checked ? 1 : 0

        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: checked,
        }))

        // Отправляем данные на сервер
        await sendDataToServer(name, newValue)
    };

    // Получение данных пользователя с сервера
    const fetchUserData = useCallback(async () => {
        dispatch(updateLoader(true)); // Показываем лоадер при загрузке данных
        try {
            const response = await fetch(`https://spaceshipbridge.com/ud/api.php?action=getcloackdata&id=${id}`);
            const json = await response.json();

            setURL1(json.url1 || '')
            setURL2(json.url2 || '')
            setUdPath(json.ud_path)
            setCountries(json.countries || '')
            setCountriesStop(json.countriesstop || '')
            setDelay(json.delay || 0)
            setCloackName(json.name)
            setSplit(json.split)
            setSplitValues(json.splitvalues)
            setGetVars(json.getvars)
            setGetVarsValues(json.getvarsvalues)
            setGetVarsAndOr(json.getvarsandor)

            setCheckboxes((prevState) => ({
                ...prevState,
                active: json.active === 1,
                gclid: json.gclid === 1,
                vpn: json.vpn === 1,
                incognito: json.incognito === 1,
                bot: json.bot === 1,
                tampering: json.tampering === 1,
                virtual_machine: json.virtual_machine === 1,
                privacy_settings: json.privacy_settings === 1,
                root_apps: json.root_apps === 1,
                emulator: json.emulator === 1,
                app_cloners: json.app_cloners === 1,
                jailbreak: json.jailbreak === 1,
                frida: json.frida === 1,
                geo: json.geo === 1,
                windows: json.windows === 1,
                mac: json.mac === 1,
                ios: json.ios === 1,
                android: json.android === 1,
                linux: json.linux === 1,
                chromeos: json.chromeos === 1,
                redirect: json.redirect === 1,
                developer_tools: json.developer_tools === 1,
                remote_control: json.remote_control === 1,
                factory_reset: json.factory_reset === 1
            }));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false)); // Скрываем лоадер после загрузки данных
        }
    }, [id, dispatch]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const onOffCloack = async () => {
        const currentActive = checkboxes['active']
        const newActive = !currentActive;
        const newValue = newActive ? 1 : 0

        setCheckboxes((prevState) => ({
            ...prevState,
            'active': newActive,
        }))

        await sendDataToServer('active', newValue)
    }
    const redirectChange = async () => {
        const currentRedirect = checkboxes['redirect']
        const newRedirect = !currentRedirect;
        const newValue = newRedirect ? 1 : 0

        setCheckboxes((prevState) => ({
            ...prevState,
            'redirect': newRedirect
        }))

        await sendDataToServer('redirect', newValue)
    }

    const scriptURL = `${udPath}?uuid=${id}`
    let codeString = []
    codeString[1] = `<script async><?=file_get_contents('${scriptURL}')?></script>`
    codeString[2] = `<script async src="${scriptURL}"></script>`
    const [currentCode,setCurrentCode] = useState('')
    const [currentTab,setCurrentTab] = useState(1)
    const TabClick = e => {
        setCurrentTab(e)
        setCurrentCode(codeString[e])
    }

    useEffect(()=>{
        setCurrentCode(codeString[1])
        // eslint-disable-next-line
    },[udPath])

    const copyCodeClick = () => {
        navigator.clipboard.writeText(currentCode)
            .then(() => {
                // Успешно скопировано
                alert('Copied to Clipboard');
            })
            .catch(err => {
                console.error('Error: ', err);
            })
    }

    const [getVarsVar,setGetVarsVar] = useState('')
    const [getVarsEE,setGetVarsEE] = useState('notempty')
    const [getVarsValue,setGetVarsValue] = useState('')
    const getvarsSubmit = e => {
        e.preventDefault()
        dispatch(updateLoader(true))
        try {
            const stateSend = {
                action: 'addgetvarsunit',
                var: getVarsVar,
                value: getVarsValue,
                ee: getVarsEE,
                uuid: id
            };

            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                json.getvarsvalues && setGetVarsValues(json.getvarsvalues)
            })
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false))
        }
    }

    const splitSubmit = e => {
        e.preventDefault()
        dispatch(updateLoader(true))
        try {
            const stateSend = {
                action: 'addsplitunit',
                country: splitCountry,
                white: splitWhite,
                black: splitBlack,
                uuid: id
            };

            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                json.splitvalues && setSplitValues(json.splitvalues)
            })
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false))
        }
    }
    const getVarsChange = async (e) => {
        const newGetVarsValue = e.target.checked ? 1 : 0
        setGetVars(newGetVarsValue)
        await sendDataToServer('getvars', newGetVarsValue)
    }
    const changeGetVars = async (e) => {
        const value = e.target.value
        setGetVarsAndOr(value)
        await sendDataToServer('getvarsandor', value)
    }
    const getVarsDelete = e => {
        try {
            const stateSend = {
                action: 'deletegetvars',
                uuid: e.target.dataset.uuid,
                delete: e.target.dataset.delete
            };

            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                json.getvarsvalues && setGetVarsValues(json.getvarsvalues)
            })
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false))
        }
    }
    const splitChange = async (e) => {
        const newSplitValue = e.target.checked ? 1 : 0
        setSplit(newSplitValue)
        await sendDataToServer('split', newSplitValue)
    }
    const splitDelete = e => {
        try {
            const stateSend = {
                action: 'deletesplit',
                uuid: e.target.dataset.uuid,
                delete: e.target.dataset.delete
            };

            fetch('https://spaceshipbridge.com/ud/api.php', {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stateSend)
            })
            .then(response => response.json())
            .then(json => {
                json.splitvalues && setSplitValues(json.splitvalues)
            })
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLoader(false))
        }
    }

    return (
        <>
            <h1 className="go_back">
                <a href="/cloaks"><FontAwesomeIcon icon={faArrowLeft} /></a>
                <input className="h1_input" name="name" value={cloackName} onChange={handleInputChange(setCloackName, 'name')} />
                <button
                    onClick={onOffCloack}
                    name="active"
                    value={checkboxes['active']}
                >
                    {checkboxes['active']
                        ?
                        <FontAwesomeIcon icon={faToggleOn} className="green" />
                        :
                        <FontAwesomeIcon icon={faToggleOff} className="red" />
                    }
                </button>
            </h1>

            {/* Текстовые инпуты */}
            <div className="settings_columns">
                <div className="form-control_wrapper">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        id="url1"
                        name="url1"
                        value={url1}
                        onChange={handleInputChange(setURL1, 'url1')}
                    />
                    <label htmlFor="url1" className="form-control-label">White Page URL</label>
                </div>
                <div className="form-control_wrapper">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        id="url2"
                        name="url2"
                        value={url2}
                        onChange={handleInputChange(setURL2, 'url2')}
                    />
                    <label htmlFor="url2" className="form-control-label">Black Page URL</label>
                    <span className="form-control_wrapper_help"><FontAwesomeIcon icon={faCircleInfo} /></span>
                    <div className="form-control_wrapper_help_popup">Если домен Black не совпадает с White используйте редирект</div>
                </div>
            </div>
            <div className="settings_columns_checkboxes">
                <div className="form-control_wrapper">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        id="countries"
                        name="countries"
                        value={countries}
                        onChange={handleInputChange(setCountries, 'countries')}
                    />
                    <label htmlFor="countries" className="form-control-label">Countries</label>
                    <div className="form-control_wrapper_text">Список разрешенных стран. Пользователи из всех остальных стран будут отправлены на white page. Указывайте страны через запятую в формате ISO 3166-1 alpha-2. <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2" target="_blank" rel="noopener noreferrer">Коды стран</a>.</div>
                </div>
                <div className="form-control_wrapper">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        id="countriesstop"
                        name="countriesstop"
                        value={countriesStop}
                        onChange={handleInputChange(setCountriesStop, 'countriesstop')}
                    />
                    <label htmlFor="countriesstop" className="form-control-label">Countries Stop</label>
                    <div className="form-control_wrapper_text">Список заблокированных стран нужно использовать, если Allowed Countries пустой и вы хотите пропускать пользователей со всех стран мира, кроме указанных.</div>
                </div>
                <div className="form-control_wrapper">
                    <input
                        type="number"
                        min="0"
                        step="100"
                        className="form-control"
                        placeholder=" "
                        id="delay"
                        name="delay"
                        value={delay}
                        onChange={handleInputChange(setDelay, 'delay')}
                    />
                    <label htmlFor="delay" className="form-control-label">Cloak Delay, ms</label>
                    <div className="form-control_wrapper_text">Задержка срабатывания клоаки в миллисекундах</div>
                </div>
                <div className="form-control_wrapper">
                    <select
                        className="form-control"
                        id="redirect"
                        value={checkboxes['redirect']}
                        onChange={redirectChange}
                    >
                        <option value="false">In Frame (подмена контента)</option>
                        <option value="true">With Redirect</option>
                    </select>
                    <label htmlFor="redirect" className="form-control-label">Как отображать Black page</label>
                    <div className="form-control_wrapper_text">
                        {!checkboxes['redirect'] && `При использовании типа отображения black page “in frame” black page должна находиться на том же домене, что и white page. Этот тип отображения black page показывает black page без изменения адреса в адресной строке.`}
                        {checkboxes['redirect'] && `Пользователь будет перенаправлен на black page с помощью обычного редиректа, поэтому можно использовать black page на любом домене.`}
                    </div>
                </div>
            </div>

            {/* Чекбоксы */}
            <div className="settings_columns_checkboxes">
                {[
                    'android', 'ios', 'windows', 'mac', 'linux', 'chromeos', 'vpn', 'incognito',
                    'bot', 'tampering', 'virtual_machine', 'privacy_settings', 'root_apps',
                    'emulator', 'app_cloners', 'jailbreak', 'frida', 'geo', 'developer_tools', 'remote_control', 'factory_reset'
                ].map((checkboxName, i) => (
                    <div key={`settingscolumnscheckboxes${i}`}>
                        <label className="label">
                            <input
                                className="checkbox"
                                type="checkbox"
                                name={checkboxName}
                                onChange={checkboxesChange}
                                checked={checkboxes[checkboxName]}
                            />
                            <span className="settings_columns_checkboxes_text">{smartSignalsRelations[checkboxName] ? smartSignalsRelations[checkboxName].label : checkboxName}</span>
                            {smartSignalsRelations[checkboxName]?.icon && (
                                <FontAwesomeIcon icon={smartSignalsRelations[checkboxName].icon} className={checkboxName} />
                            )}
                        </label>
                    </div>
                ))}
            </div>
            {/*
            <div className="settings_columns_checkboxes">
                {[
                    'gclid'
                ].map((checkboxName, i) => (
                    <div key={`settingscolumnscheckboxes${i}`}>
                        <label className="label">
                            <input
                                className="checkbox"
                                type="checkbox"
                                name={checkboxName}
                                onChange={checkboxesChange}
                                checked={checkboxes[checkboxName]}
                            />
                            <span className="settings_columns_checkboxes_text">{smartSignalsRelations[checkboxName] ? smartSignalsRelations[checkboxName].label : checkboxName}</span>
                            {smartSignalsRelations[checkboxName]?.icon && (
                                <FontAwesomeIcon icon={smartSignalsRelations[checkboxName].icon} className={checkboxName} />
                            )}
                        </label>
                        {smartSignalsRelations[checkboxName].tip && <div className="form-control_wrapper_text">{smartSignalsRelations[checkboxName].tip}</div>}
                    </div>
                ))}
            </div>
            */}

            <div className="settings_split">
                <label className="settings_split_label">
                    <input
                        className="settings_split_checkbox"
                        type="checkbox"
                        checked={getVars === 1 ? true : false}
                        onChange={getVarsChange}
                    />
                    <strong>Обязательные GET параметры</strong>
                </label>
                <select
                    className="settings_split_select form-control"
                    value={getVarsAndOr}
                    onChange={changeGetVars}
                >
                    <option value="and">Учитывать все GET параметры</option>
                    <option value="or">Учитывать один из GET параметров</option>
                </select>
                <div className="settings_split_columns">
                    <form className="settings_getvars_grid" onSubmit={getvarsSubmit}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Имя переменной"
                            value={getVarsVar}
                            onChange={e => setGetVarsVar(e.target.value)}
                            required
                        />
                        <select
                            className="form-control"
                            value={getVarsEE}
                            onChange={e => setGetVarsEE(e.target.value)}
                        >
                            <option value="notempty">Не пустая</option>
                            <option value="equals">Равна</option>
                        </select>
                        <input
                            type="text"
                            className={[`form-control`,getVarsEE === 'notempty' ? 'hidden' : ''].join(' ')}
                            placeholder="Значение переменной"
                            value={getVarsValue}
                            onChange={e => setGetVarsValue(e.target.value)}
                        />
                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faAdd} /></button>
                    </form>
                    {getVarsValues.map((e,i) =>
                        <div className="settings_getvars_grid_values" key={`getvars${i}`}>
                            <div className="settings_split_grid_unit">{e.var}</div>
                            <div className="settings_split_grid_unit">{e.value}</div>
                            <button
                                className="btn btn-danger"
                                data-delete={`${e.var}::${e.value}`}
                                data-uuid={id}
                                onClick={getVarsDelete}
                            ><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                    )}
                </div>
            </div>

            <div className="settings_split">
                <label className="settings_split_label">
                    <input
                        className="settings_split_checkbox"
                        type="checkbox"
                        checked={split === 1 ? true : false}
                        onChange={splitChange}
                    />
                    <strong>Split</strong> &mdash; отработка разных Black по разным White и странам (должны быть указаны в разрешенных)
                </label>
                <div className="settings_split_columns">
                    <form className="settings_split_grid" onSubmit={splitSubmit}>
                        <select
                            className="form-control"
                            value={splitCountry}
                            onChange={e => setSplitCountry(e.target.value)}
                        >
                        {Object.entries(countriesJson).map(([code, name]) => (
                            <option key={`countryoption${code}`} value={code}>
                            {name}
                            </option>
                        ))}
                        </select>
                        <span className="settings_split_grid_span">:</span>
                        <input
                            type="url"
                            className="form-control"
                            placeholder="White URL"
                            value={splitWhite}
                            onChange={e => setSplitWhite(e.target.value)}
                            required
                        />
                        <span className="settings_split_grid_span">&rarr;</span>
                        <input
                            type="url"
                            className="form-control"
                            placeholder="Black URL"
                            value={splitBlack}
                            onChange={e => setSplitBlack(e.target.value)}
                            required
                        />
                        <button className="btn btn-primary" type="submit"><FontAwesomeIcon icon={faAdd} /></button>

                    </form>
                    {splitValues.map((e,i) =>
                        <div className="settings_split_grid" key={`splitunit${i}`}>
                            <div className="settings_split_grid_unit">{countriesJson[e.country]} ({e.country})</div>
                            <span className="settings_split_grid_span">:</span>
                            <div className="settings_split_grid_unit">{e.white}</div>
                            <span className="settings_split_grid_span">&rarr;</span>
                            <div className="settings_split_grid_unit">{e.black}</div>
                            <button
                                className="btn btn-danger"
                                data-delete={`${e.country}|${e.white}|${e.black}`}
                                data-uuid={id}
                                onClick={splitDelete}
                            ><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                    )}
                </div>
            </div>

            <div className="settings_code_tabs">
                <button
                    className={[`settings_code_tabs_button`,currentTab === 1 ? 'current' : 'notcurrent'].join(' ')}
                    onClick={() => TabClick(1)}
                >php или WP</button>
                <button
                    className={[`settings_code_tabs_button`,currentTab === 2 ? 'current' : 'notcurrent'].join(' ')}
                    onClick={() => TabClick(2)}
                >HTML</button>
            </div>
            <div className="settings_code">
                <pre>
                    <code>
                        <div className="settings_code_non_selectable">
                            &lt;!DOCTYPE html&gt;<br />
                            &lt;html lang="en"&gt;<br />
                            &lt;head&gt;
                        </div>
                        {currentCode}
                        <div className="settings_code_non_selectable">
                            &lt;meta charset="UTF-8"&gt;<br />
                            &lt;meta name="viewport" content="width=device-width, initial-scale=1.0"&gt;
                        </div>
                    </code>
                </pre>
                <button className="btn btn-outline" onClick={copyCodeClick}><FontAwesomeIcon icon={faCopy} /> Копировать</button>
            </div>
            <p>Добавьте этот код сразу после <strong>&lt;head&gt;</strong>. <a href="/help#howtoplace">Узнать больше</a> как это использовать.</p>
        </>
    );
};
