import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"

import statSlice from "./statSlice"

const rootReducer = combineReducers({
    stat: statSlice
})

export const store = configureStore({
    reducer: rootReducer,
})

export { rootReducer }