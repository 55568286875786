import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Home } from './components/Home'
import { Account } from './components/Account'
// import { Billing } from './components/Billing'
import { Cloack } from './components/Cloack'
import { Cloacks } from './components/Cloacks'
import { LoginPage } from './components/LoginPage'
import { Help } from './components/Help'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faShield, faUser, faCircleQuestion, faBars, faClose, faChartLine } from '@fortawesome/free-solid-svg-icons'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { updateUserData, updateTopMenu } from './redux/statSlice'
import { daysUntil } from './functions'

import { ReactComponent as Logo } from './logo.svg'

import './App.css'
import { Changelog } from './components/Changelog'

const App = () => {

    const dispatch = useDispatch()

    const logOut = () => {
        localStorage.removeItem('user.id')
        localStorage.removeItem('was.auth')
        window.location.href = "/"
    }

    const statData = useSelector((state) => state.stat)

    const showHideMenu = () => {
        const newValue = statData.topmenu ? false : true
        dispatch(updateTopMenu(newValue))
    }

    useEffect(() => {

        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://spaceshipbridge.com/ud/api.php?action=getuserdata&id=${localStorage.getItem('user.id')}`);
                const json = await response.json();
                if (json.expires) {
                    dispatch(updateUserData({
                        daysleft: daysUntil(json.expires),
                        expires: json.expires,
                        registered: json.registered,
                        email: json.email,
                        payments: json.payments
                    }))
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchUserData();

    }, [dispatch])

    const HeaderMenu = () => {
        return (
            <ul className={[`header_menu`].join(' ')}>
                <li><a className="header_menu_link" href="/"><FontAwesomeIcon icon={faChartLine} />Статистика</a></li>
                <li><a className="header_menu_link" href="/cloaks"><FontAwesomeIcon icon={faShield} />Клоаки</a></li>
                <li><a className="header_menu_link" href="/help"><FontAwesomeIcon icon={faCircleQuestion} />Помощь</a></li>
                <li><a className="header_menu_link" href="/account"><FontAwesomeIcon icon={faUser} />Аккаунт</a></li>
                <li><button className="header_menu_link header_menu_link_logout" type="button" onClick={logOut}><FontAwesomeIcon icon={faArrowRightFromBracket} /><span className="logout">Выйти</span></button></li>
            </ul>
        )
    }

    return (
        <div className="App">
            <Router>
                {localStorage.getItem('user.id')
                ?
                <>
                    <header className="header">
                        <a className="logo" href="/">
                            <Logo />
                            <span className="logo_text">Cloak</span>
                        </a>
                        {statData.passed > 0 || statData.blocked > 0
                        ?
                        <div className="stat_columns">
                            <div><span className="hide1000">Прошло:</span> <strong className="green">{statData.passed}</strong></div>
                            <div><span className="hide1000">Блокировано:</span> <strong className="red">{statData.blocked}</strong></div>
                            <div><span className="hide1000">Всего:</span> <strong>{statData.total}</strong></div>
                        </div>
                        :
                        ''}
                        {statData.daysleft
                        ?
                        <div className="stat_columns">
                            <div><span className="hide1000">Осталось:</span> <strong>{statData.daysleft}</strong></div>
                        </div>
                        :
                        ''}
                        <HeaderMenu />
                        <button className="btn btn-default burger_menu" onClick={showHideMenu}>
                            {statData.topmenu
                            ?
                            <FontAwesomeIcon icon={faClose} />
                            :
                            <FontAwesomeIcon icon={faBars} />
                            }
                        </button>
                    </header>
                    <div className={[`under_header`,statData.topmenu ? 'active' : 'notactive'].join(' ')}><HeaderMenu /></div>
                    <main className="content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/help" element={<Help />} />
                            <Route path="/cloaks" element={<Cloacks />} />
                            <Route path="/cloak/:id" element={<Cloack />} />
                            <Route path="/changelog" element={<Changelog />} />
                        </Routes>
                    </main>
                    <footer className="footer">
                        <div>Made with &#x1F9E1; in <a href="https://knkads.com/" target="_blank" rel="noopener noreferrer">K&K Ads</a></div>
                        <div>Версия <a href="/changelog">12.12.2024</a></div>
                    </footer>
                </>
                :
                <LoginPage />
                }
                <div className={[`loader`,statData.loading ? 'active' : 'notactive'].join(' ')}></div>
            </Router>
        </div>
    )
}

export default App

